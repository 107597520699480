import { DELIVERY_PICKUP_DATE_FILTERS } from '@/config/searchKit';
import {
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue
} from '@/services/localStorage.service';

const DeliveryPickupRadioGroup = ({
  selectedDeliveryPickupDateFilter,
  setSelectedDeliveryPickupDateFilter
}) => {
  const handleClick = (value) => {
    setLocalStorageValue({
      key: LOCAL_STORAGE_KEYS.DATE_FILTER_KEY,
      value
    });
    setSelectedDeliveryPickupDateFilter(value);
  };

  return (
    <ul className='flex flex-col gap-4'>
      {Object.values(DELIVERY_PICKUP_DATE_FILTERS).map(
        ({ key, value, label }) => (
          <li
            key={key}
            className='relative block searchkit-filter-menu-item cursor-pointer'
          >
            <label className='cursor-pointer block'>
              <input
                checked={selectedDeliveryPickupDateFilter === value}
                className='checked:content-searchkit-filter-menu-item-checked searchkit-filter-menu-item-checked searchkit-radio'
                name='delivery-options'
                onChange={() => handleClick(value)}
                type='radio'
                value={value}
              />
              <span className='before:bg-searchkit-filter-menu-item empty-radio-span inline-block w-5 h-5'></span>
              <span className='text-sm text-dim-gray font-medium pl-2 self-center'>
                {label}
              </span>
            </label>
          </li>
        )
      )}
    </ul>
  );
};

export default DeliveryPickupRadioGroup;
