import { BID_LIST_INDEX, ORDER_LIST_INDEX } from '@/config/common';
import { PARTNER_API_BASE_URL } from '@/services/connections.service';

export const DEFAULT_HITS_PER_PAGE = 10;

export const DEFAULT_FILTER_OPTIONS_LIMIT = 5;

export const searchKitAPIEndPoint = {
  BID_LIST: `${PARTNER_API_BASE_URL}/bid-list`,
  DELIVERY_PICKUP_LIST: `${PARTNER_API_BASE_URL}/logistics`,
  ORDER_LIST: `${PARTNER_API_BASE_URL}/order-list`
};

export const GENERIC_ALL_TAB_CATEGORY_LABEL = 'ALL';

export const SEARCH_ATTRIBUTE_LIST_BIDS = {
  ALL: { key: null, label: 'All', value: GENERIC_ALL_TAB_CATEGORY_LABEL },
  EVENT_LOCATION: {
    key: 'eventLocation',
    label: 'Event Location',
    value: 'EVENT_LOCATION'
  },
  QUOTE_NUMBER: {
    key: 'quoteNumber',
    label: 'Quote Number',
    value: 'QUOTE_NUMBER'
  }
};

export const SEARCH_ATTRIBUTE_LIST_ORDERS = {
  ALL: { key: null, label: 'All', value: GENERIC_ALL_TAB_CATEGORY_LABEL },
  EVENT_LOCATION: {
    key: 'eventLocation',
    label: 'Event Location',
    value: 'EVENT_LOCATION'
  },
  QUOTE_NUMBER: {
    key: 'orderNumber',
    label: 'Order Number',
    value: 'ORDER_NUMBER'
  },
  PRODUCT_NAME: {
    key: 'productNames',
    label: 'Product Name',
    value: 'PRODUCT_NAME'
  }
};

export const DELIVERY_PICKUP_DATE_FILTERS = {
  ALL: { key: 'all', label: 'All', value: 'ALL' },
  DELIVERY_TODAY: {
    key: 'deliveryToday',
    label: 'Delivery Today',
    value: 'DELIVERY_TODAY'
  },
  DELIVERY_TOMORROW: {
    key: 'deliveryTomorrow',
    label: 'Delivery Tomorrow',
    value: 'DELIVERY_TOMORROW'
  },
  PICKUP_TODAY: {
    key: 'pickupToday',
    label: 'Pickup Today',
    value: 'PICKUP_TODAY'
  },
  PICKUP_TOMORROW: {
    key: 'pickupTomorrow',
    label: 'Pickup Tomorrow',
    value: 'PICKUP_TOMORROW'
  }
};

export const SORT_BY_ITEMS_ORDER_LIST = [
  {
    label: 'Event Date: Newest first',
    value: `${ORDER_LIST_INDEX}`
  },
  {
    label: 'Event Date: Oldest first',
    value: `${ORDER_LIST_INDEX}_event_asc`
  },
  {
    label: 'Grand Total: High to low',
    value: `${ORDER_LIST_INDEX}_partnerOrderTotal_desc`
  }
];

export const SORT_BY_ITEMS_BID_LIST = [
  {
    label: 'Last Updated at: Newest first',
    value: `${BID_LIST_INDEX}`
  },
  {
    label: 'Last Updated at: Oldest first',
    value: `${BID_LIST_INDEX}_bidUpdatedAt_asc`
  },
  {
    label: 'Event Date: Newest first',
    value: `${BID_LIST_INDEX}_event_desc`
  },
  {
    label: 'Event Date: Oldest first',
    value: `${BID_LIST_INDEX}_event_asc`
  }
];
